import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "../state/app-state";
import { UnlayerState } from "../state/unlayer-state";

export const selectTemplateOpen = createFeatureSelector<AppState, UnlayerState>('unlayerState');

export const selectAllTemplateOpen = createSelector(
    selectTemplateOpen,
    (state: UnlayerState) => {
        if (state) {
            return state.templateOpen;
        }
        else {
            return null;
        }
    }
);