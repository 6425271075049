<app-header *ngIf="userLogged" [b2sContent]="b2sContent"></app-header>

<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-primary topbar shadow">

  <a class="navbar-brand" [routerLink]="['/login']">
    <img class="logo" src="{{logo}}" alt="Logo">
  </a>

  <div class="collapse navbar-collapse" id="navbarCollapse">

    <ul class="navbar-nav mr-auto">
    </ul>

    <ul class="navbar-nav">
      <li class="nav-item">
        <a [routerLink]="['','login']" class="btn btn-link btn-block" style="color:#ffffff; cursor: pointer;">Iniciar
          Sesión</a>
      </li>
    </ul>
  </div>
</nav>

<!-- Main Content -->
<main role="main" class="content__main">
  <!-- Begin Begin Page Content -->
  <div class="container pt-3">
    <!-- Title -->
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h1 class="font-weight-bold h3 mb-0 text-gray">Bienvenido al Registro de Proveedores</h1>
    </div>
    <!-- End Title -->

    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/account-statements', 0]"><i class="fas fa-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Registro de Proveedores</li>
      </ol>
    </nav>
    <!-- End Breadcrumb -->

    <!-- Content -->
    <div class="row">

      <!-- Header -->
      <div class="col-md-12">
        <div *ngIf="cmsData" class="banner">
          <app-advertisements *ngIf="cmsData.espacios[1]" [typeContent]="'espacios'" [positions]="1"
            [contentData]="cmsData"></app-advertisements>
        </div>
        <!-- <h4 class="font-weight-bold text-primary">Bienvenido al Registro de Proveedores</h4> -->
        <!-- Content template cms-->
        <div *ngIf="cmsMessage" class="banner" [innerHTML]="cmsMessage.contenido">
        </div>
        <!-- End content template cms-->
      </div>
      <!-- End Header -->

      <!-- Form -->
      <div class="col-md-12 mb-4 order-md-1">
        <form novalidate="" class="form__default" #forma="ngForm" (ngSubmit)="onSubmit(forma)">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-search icon-form"></i></span>
                </div>
                <input [(ngModel)]="dataForm.nitCompany" #company="ngModel" name="company" type="text"
                  class="form-control" placeholder="Escribe el NIT de la compañia" required autofocus>
              </div>
              <div *ngIf="company.touched && company.invalid">
                <div *ngIf="dataForm.nitCompany.length <= 0" class="text-danger">
                  El NIT es un campo requerido
                </div>
              </div>
              <div *ngIf="messageError" class="text-danger">
                {{ messageError }}
              </div>
            </div>
            <div class="col-md-6 md-3">
              <div class="row">
                <div class="col-md-2">
                  <button type="submit" class="btn btn-primary float-center">Buscar</button>
                </div>
                <!-- <div class="col-md-4">
                    <a class="btn btn-primary float-center">
                        <span class="text">Limpiar</span>
                    </a>
                </div> -->
                <div class="col-md-5" *ngIf="this.companyInfo.length === 0">
                  <a class="btn btn-primary float-center" [routerLink]="['','supplier-registration']">
                    <span class="text">Solicitar Registro</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 mb-3">
              <div *ngIf="messageErrorResponse">
                <h6 class="font-weight-bold">{{ messageErrorResponse }}</h6>
              </div>
            </div>

            <!-- Informacion de los proveedores -->

            <div *ngIf="this.companyInfo.length > 0" class="col-md-12 mb-3">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">Nit</th>
                    <th scope="col" class="text-center">Razón social</th>
                    <th scope="col" class="text-center">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let proveedor of this.companyInfo" class="text-center">
                    <th scope="row">{{ proveedor.documento_numero }}</th>
                    <td>{{ proveedor.nombre }}</td>
                    <td>
                      <button (click)="requestSuplier(proveedor.documento_numero)"
                        class="btn btn-primary float-center">Solicitar acceso</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Mensaje informativo -->
            <div class="text-center" *ngIf="this.mostrarMensaje">
              <h4>¡Lo sentimos, aún no eres nuestro proveedor!</h4>
              <p>
                El NIT digitado no corresponde a una empresa proveedora registrada en nuestro sistema de gestión
                de proveedores. Si deseas ser parte de nuestro portafolio de proveedores, por favor hacer click en
                “Solicitar Registro”.
              </p>
            </div>

            <!-- Fin informacion de los proveedores -->

            <div *ngIf="!companyInfo" class="col-md-12 mb-3">
              <br>
              <br>
              <br>
              <br>
              <br>
            </div>
          </div>
        </form>
      </div>
      <!-- End Form -->

    </div>
    <!-- End Content -->
    <div class="col-md-12">
      <div *ngIf="cmsData" class="banner">
        <app-advertisements *ngIf="cmsData.espacios[3]" [typeContent]="'espacios'" [positions]="3"
          [contentData]="cmsData"></app-advertisements>
      </div>
    </div>

  </div>
  <!-- End Begin Page Content -->
</main>
<!-- Main Content -->
<!-- <app-footer [b2sContent]="b2sContent"></app-footer> -->