<app-header *ngIf="isAuthenticate" [b2sContent]="b2sContent"></app-header>
<app-header-public *ngIf="!isAuthenticate"></app-header-public>
<main role="main" class="content__main">

  <!-- Container -->
  <div class="container">
    <!-- Title -->
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <!-- <h1 class="font-weight-bold h3 mb-0 text-gray">Contáctenos</h1> -->
    </div>
    <!-- End Title -->

    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a class="breadcrumb-item--link" [routerLink]="['/account-statements', 0]"><i
              class="fas fa-home"></i></a>
        </li>
        <!-- <li class="breadcrumb-item active" aria-current="page">Contáctenos</li> -->
      </ol>
    </nav>

    <div class="col-md-12">
      <div class="banner">
        <img class="img-fluid" src="../assets/images/contactenos.jpg" alt="">
      </div>
      <h4 class="font-weight-bold text-primary">Gracias por Contactarnos</h4>
      <p class="text-justify">Nos puede contactar a través del siguiente formulario:</p>
    </div>
    <!-- End Breadcrumb -->

    <!-- Content -->
    <div class="row">

      <!-- Espacio N°1 -->
      <div class="col-md-12" *ngIf="cmsData">
        <div class="banner">
          <app-advertisements *ngIf="cmsData.espacios[1]" [typeContent]="'espacios'" [positions]="1"
            [contentData]="cmsData"></app-advertisements>
        </div>
      </div>
      <!-- End Espacio N°1 -->

      <!-- Espacio N°2 -->
      <div class="col-md-12 mb-4 order-md-1" *ngIf="cmsData">
        <app-advertisements *ngIf="cmsData.espacios[2]" [typeContent]="'espacios'" [positions]="2"
          [contentData]="cmsData"></app-advertisements>
      </div>
      <!-- End Espacio N°2 -->

      <!-- Form -->
      <div class="col-md-8 mb-5 order-md-1">
        <h4 class="font-weight-bold text-primary">Escríbenos</h4>
        <label> Campos requeridos (<span class="text-danger">*</span>) </label>
        <form #f="ngForm" autocomplete="off" (ngSubmit)="onSubmit(f)" name="FormControl" novalidate
          class="form__default">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-user icon-form"></i></span>
                </div>
                <input [(ngModel)]="contactUsModel.name" appOnlyLetters name="name" type="text" realname="nombre"
                  class="form-control" placeholder="* Escríbe tu(s) nombre(s)" required autofocus>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-user icon-form"></i></span>
                </div>
                <input [(ngModel)]="contactUsModel.surname" appOnlyLetters name="surname" realname="apellido"
                  type="text" class="form-control" placeholder="* Escríbe tu(s) apellido(s)" required autofocus>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="far fa-envelope icon-form"></i></span>
              </div>
              <input [(ngModel)]="contactUsModel.email" name="email" type="email" realname="correo" class="form-control"
                placeholder="* Escríbe tu correo electrónico" required
                pattern='^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$' email #email="ngModel"
                autofocus>
            </div>
            <div *ngIf="email.touched && email.invalid">
              <div *ngIf="email.errors.pattern" class="text-danger">
                Ingrese un correo válido.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-mobile-alt icon-form"></i></span>
                </div>
                <input [(ngModel)]="contactUsModel.indicative" appOnlyNumbers name="indicative" realname="indicativo"
                  class="form-control col-4" placeholder="* Indicativo" type="tel" required maxlength="4" autofocus>
                <input [(ngModel)]="contactUsModel.phone" appOnlyNumbers name="phone" realname="teléfono"
                  class="form-control" placeholder="* Escríbe tu teléfono móvil" type="tel" required maxlength="10"
                  autofocus #phone="ngModel">
              </div>
              <div *ngIf="phone.touched">
                <div *ngIf="phone.value.length < 10" class="text-danger">
                  El Teléfono Móvil debe tener 10 digitos.
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-globe-americas icon-form"></i></span>
                </div>
                <input [(ngModel)]="contactUsModel.city" type="text" appOnlyLetters name="city" realname="ciudad"
                  class="form-control" placeholder="* Escríbe tu Ciudad de residencia" required autofocus>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-building icon-form"></i></span>
                </div>
                <input ngModel type="text" name="company" realname="compañia" class="form-control"
                  placeholder="Escríbe el nombre de la empresa" autofocus>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-briefcase icon-form"></i></span>
                </div>
                <input ngModel type="text" name="job-title" realname="cargo" class="form-control"
                  placeholder="Escríbe tu cargo" autofocus>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3" *ngIf="affairs != undefined">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-envelope-open-text"></i></span>
                </div>
                <select ngModel name="subject" realname="asunto" class="custom-select" required autofocus>
                  <option value="">* Seleccione un asunto</option>
                  <option *ngFor="let affair of affairs" value="{{affair}}">{{affair}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-3" *ngIf="relatedto != undefined">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-list-ul"></i></span>
                </div>
                <select ngModel name="related-to" class="custom-select" realname="relacionado con" autofocus>
                  <option value="">Relaciondo con</option>
                  <option *ngFor="let related of relatedto" value="{{related.valor}}">{{related.valor}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="far fa-comment-dots"></i></span>
              </div>
              <textarea ngModel name="message" realname="descripción del asunto" class="form-control"
                placeholder="* Describe tu asunto..." required autofocus></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3" *ngIf="contactThroughs != undefined">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-comments"></i></span>
                </div>
                <select ngModel class="custom-select" name="affair-contact" realname="via de contacto" required
                  autofocus>
                  <option value="">* Elije vía de contacto</option>
                  <option *ngFor="let contactThrough of contactThroughs" value="{{contactThrough.nombre}}">
                    {{contactThrough.nombre}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-calendar-alt"></i></span>
                </div>
                <input ngModel type="text" name="schedule" realname="horario" class="form-control" placeholder="Horario"
                  autofocus>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-check">
                <input ngModel type="checkbox" class="form-check-input" name="check-acepto"
                  realname="términos y condiciones" required autofocus>
                <label class="form-check-label">
                  <a *ngIf="isAuthenticate" href="/#/terms-and-conditions" target="_blank">* Acepto términos y
                    condiciones</a>
                  <a *ngIf="!isAuthenticate" href="/#/terms" target="_blank">* Acepto términos y condiciones</a>
                </label>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-check">
                <input ngModel type="checkbox" class="form-check-input" name="check-autorizo"
                  realname="tratamiento de datos" required autofocus>
                <label class="form-check-label">
                  <a *ngIf="isAuthenticate" href="/#/privacy-policy" target="_blank">* Autorizo el tratamiento de
                    datos</a>
                  <a *ngIf="!isAuthenticate" href="/#/privacy" target="_blank">* Autorizo el tratamiento de datos</a>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <re-captcha ngModel name="token-capcha" realname="capcha" required></re-captcha>
            </div>
            <div class="text-center col-md-6 mb-3">
              <button type="submit" class="btn btn-primary btn-icon-split mt-3 mb-3 mr-5"
                [disabled]="!f.valid || disabledButton">
                <span class="text">Enviar Mensaje</span>
                <span class="icon text-white-50">
                  <i class="fa fa-paper-plane"></i>
                </span>
              </button>
            </div>
          </div>
        </form>

      </div>
      <div *ngIf="companieInfo != undefined" class="col-md-4 order-md-2 mb-5">
        <h4 class="font-weight-bold text-primary">Contáctenos</h4>
        <div class="card mb-4 py-0 border-left-primary">
          <div class="card-body">
            <p>Si deseas comunicarte con una persona de servicio utiliza la siguiente información:</p>
            <!-- <p>Servicion al cliente</p> -->
            <span class="text-muted"><i class="fas fa-phone icon-sidebar"></i></span>
            <span *ngIf="companieInfo != undefined && companieInfo.telefono.length > 0"> {{
              companieInfo.indicativo}} {{companieInfo.telefono}}</span>
            <span *ngIf="companieInfo != undefined && companieInfo.ext.length == 0"><br></span>
            <span *ngIf="companieInfo != undefined && companieInfo.ext.length > 0"> <b> Ext:</b> {{
              companieInfo.ext}}<br></span>
            <span class="text-muted"><i class="fas fa-mobile-alt icon-sidebar"></i></span>
            <span *ngIf="companieInfo != undefined && companieInfo.correo.length > 0">
              {{companieInfo.numero_tele}}<br></span>
            <!-- <span class="text-muted"><i class="fas fa-mobile-alt icon-sidebar"></i></span>
            <span *ngIf="companieInfo != undefined && companieInfo.correo.length > 0">
              {{companieInfo.telefono_2}}<br></span> -->
            <span class="text-muted"><i class="far fa-envelope icon-sidebar"></i></span>
            <span *ngIf="companieInfo != undefined && companieInfo.correo.length > 0">
              {{companieInfo.correo}}<br></span>
            <span class="text-muted"><i class="fas fa-clock icon-sidebar"></i></span>
            <span *ngIf="companieInfo != undefined && companieInfo.horario.length > 0">
              {{companieInfo.horario}}<br></span>
            <span class="text-muted"><i class="fas fa-map-marker icon-sidebar"></i></span>
            <span *ngIf="companieInfo != undefined && companieInfo.dir.length > 0"> {{companieInfo.dir}}</span>
          </div>
        </div>
      </div>

      <!-- End Form -->


    </div>
    <!-- End Content -->
    <div class="col-md-12 mb-4 ">
      <div *ngIf="cmsData">
        <app-advertisements *ngIf="cmsData.espacios[3]" [typeContent]="'espacios'" [positions]="3"
          [contentData]="cmsData"></app-advertisements>
      </div>
    </div>
  </div>

  <!-- End Container -->

</main>
<app-footer [b2sContent]="b2sContent"></app-footer>