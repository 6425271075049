<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-primary topbar shadow">

  <a class="navbar-brand" [routerLink]="['/account-statements', 0]">
    <img class="logo" src="{{logo}}" alt="Logo">
  </a>

  <button id="sidebarToggleTop" class="navbar-toggler btn btn-link d-md-none rounded-circle" type="button"
    data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
    aria-label="Toggle navigation">
    <i class="fa fa-bars"></i>
  </button>

  <div class="collapse navbar-collapse" id="navbarCollapse">

    <ul class="navbar-nav mr-auto" *ngIf="!existContent(menuContent)">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="consultDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Estados de Cuenta
        </a>
        <!-- Dropdown - User Information -->
        <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in" aria-labelledby="consultDropdown">
          <!-- <a class="dropdown-item" [routerLink]="['/account-statements', 0]">
            <i class="fas fa-file-alt fa-sm fa-fw mr-1 text-gray-400"></i>
            Estado de Cuenta
          </a> -->
          <!--<a class="dropdown-item" [routerLink]="['','late-payments']">-->
          <!--<i class="fas fa-file-invoice fa-sm fa-fw mr-1 text-gray-400"></i>-->
          <!--Pagos Pendientes-->
          <!--</a>-->
          <a class="dropdown-item" [routerLink]="['/account-statements', 0]">
            <i class="fas fa-file-invoice-dollar fa-sm fa-fw mr-1 text-gray-400"></i>
            Facturas Pendientes de Pago
          </a>
          <a class="dropdown-item" [routerLink]="['/payments-made/',payedRangeDays]">
            <i class="fas fa-file-invoice-dollar fa-sm fa-fw mr-1 text-gray-400"></i>
            Facturas Pagadas
          </a>
        </div>
      </li>
      <li class="nav-item dropdown"
        *ngIf="b2sContent && (b2sContent.orden_compra > 0 || b2sContent && b2sContent.orden_ticket > 0 || b2sContent && b2sContent.orden_notas_debito > 0)">
        <a class="nav-link dropdown-toggle" href="#" id="consultDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Consultas
        </a>
        <!-- Dropdown - User Information -->
        <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in" aria-labelledby="consultDropdown">
          <!-- <a class="dropdown-item" [routerLink]="['/account-statements', 0]">
            <i class="fas fa-file-alt fa-sm fa-fw mr-1 text-gray-400"></i>
            Estado de Cuenta
          </a> -->
          <!--<a class="dropdown-item" [routerLink]="['','late-payments']">-->
          <!--<i class="fas fa-file-invoice fa-sm fa-fw mr-1 text-gray-400"></i>-->
          <!--Pagos Pendientes-->
          <!--</a>-->
          <a *ngIf="b2sContent && b2sContent.orden_compra > 0" class="dropdown-item"
            [routerLink]="['/purchase-order/',0]">
            <i class="fas fa-file-invoice-dollar fa-sm fa-fw mr-1 text-gray-400"></i>
            Ordenes de Compra
          </a>
          <a *ngIf="b2sContent && b2sContent.orden_ticket > 0" class="dropdown-item"
            [routerLink]="['/purchase-tickets/',0]">
            <i class="fas fa-file-invoice-dollar fa-sm fa-fw mr-1 text-gray-400"></i>
            Entradas de Almacén por Compra
          </a>
          <a *ngIf="b2sContent && b2sContent.orden_notas_debito > 0" class="dropdown-item"
            [routerLink]="['/notes-debit/',0]">
            <i class="fas fa-file-invoice-dollar fa-sm fa-fw mr-1 text-gray-400"></i>
            Notas Debito
          </a>
        </div>
      </li>
      <li *ngIf="b2sContent" class="nav-item">
        <a *ngIf="b2sContent.blog > 0" class="nav-link" [routerLink]="['','blog']">Blog</a>
      </li>
      <li *ngIf="b2sContent" class="nav-item">
        <a *ngIf="b2sContent.events > 0" class="nav-link" [routerLink]="['','supplier-register']">Eventos</a>
      </li>

      <li class="nav-item">
        <a *ngIf="b2sContent && b2sContent.certificate > 0" class="nav-link" [routerLink]="['','certificates']">
          Certificados de Retención
        </a>
      </li>
      <li *ngIf="false" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="consultDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Proveedores
        </a>
        <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in" aria-labelledby="consultDropdown">
          <a *ngIf="b2sContent && b2sContent.mostrar_contratos_proveedores > 0" class="dropdown-item"
            [routerLink]="['/supplier-contracts']">
            <i class="fas fa-file-alt fa-sm fa-fw mr-1 text-gray-400"></i>
            Contratos de Proveedores
          </a>
          <a class="dropdown-item" [routerLink]="['/supplier-qualification/']">
            <i class="fas fa-file-invoice-dollar fa-sm fa-fw mr-1 text-gray-400"></i>
            Calificación de Proveedores
          </a>
        </div>
      </li>
      <li *ngIf="b2sContent" class="nav-item">
        <a *ngIf="b2sContent.users && jsonUser.isMaster" class="nav-link" [routerLink]="['','users']">Usuarios</a>
      </li>
      <li *ngIf="b2sContent" class="nav-item">
        <a class="nav-link" [routerLink]="['','profile']">Mis Datos</a>
      </li>
    </ul>

    <ul class="navbar-nav mr-auto" *ngIf="existContent(menuContent)">
      <div *ngFor="let itemObj of menuContent | keyvalue: sortByOrder">
        <li class="nav-item" *ngFor="let itemArr of itemObj.value.data; let isFirst = first">
          <a *ngIf="!itemObj.value.group && itemArr.type == 'component'" class="nav-link"
            [routerLink]="getRouterLink(itemArr)">{{ itemArr.label }}</a>
          <a *ngIf="!itemObj.value.group && itemArr.type == 'template'" class="nav-link"
            [routerLink]="['', 'unlayer', itemArr.value]">{{ itemArr.label }}</a>
          <a *ngIf="!itemObj.value.group && itemArr.type == 'ext'" class="nav-link"
            (click)="redirectToExternalURL(itemObj.value)" style="cursor: pointer;">{{ itemArr.label }}</a>
        </li>

        <li class="nav-item dropdown" *ngIf="itemObj.key !== 'null' && itemObj.value.group">
          <a class="nav-link dropdown-toggle" href="#" id="consultDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{ itemObj.key }}
          </a>
          <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in" aria-labelledby="consultDropdown">
            <div *ngFor="let itemArr of itemObj.value.data">
              <a *ngIf="itemArr.type !== 'ext'" class="dropdown-item" [routerLink]="getRouterLink(itemArr)"
                style="cursor: pointer; color:#3a3b45;">
                <i class="fas fa-file-invoice-dollar fa-sm fa-fw mr-1 text-gray-400"></i>
                {{ itemArr.label }}
              </a>
              <div *ngIf="itemArr.type == 'ext'" class="dropdown-item" (click)="redirectToExternalURL(itemObj.value)"
                style="cursor: pointer; color:#3a3b45;">
                <i class="fas fa-file-invoice-dollar fa-sm fa-fw mr-1 text-gray-400"></i>
                {{ itemArr.label }}
              </div>
            </div>
          </div>
        </li>
      </div>
    </ul>

    <ul class="navbar-nav">
      <li class="nav-item dropdown no-arrow">
        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <span *ngIf="nameUser" class="mr-2 d-lg-inline text-white small">Bienvenid@ {{ nameUser
            }}<br>Proveedor: {{nameSupplier}}</span>
          <img class="img-profile rounded-circle" src="../../../../assets/images/usuario.svg">
        </a>
        <!-- Dropdown - User Information -->
        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
          <a class="dropdown-item" [routerLink]="['','profile']">
            <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
            Perfil
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" (click)="logout()">
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Salir
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>