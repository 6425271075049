<app-header *ngIf="userLogged" [b2sContent]="b2sContent"></app-header>
<app-header-public *ngIf="!userLogged"></app-header-public>


<!-- Main Content -->
<main role="main" class="content__main">
  <!-- Begin Begin Page Content -->
  <div class="container pt-3">
    <!-- Title -->
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h1 class="font-weight-bold h3 mb-0 text-gray">Bienvenido al Registro de Proveedores</h1>
    </div>
    <!-- End Title -->

    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/account-statements', 0]"><i class="fas fa-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Registro de Proveedores</li>
      </ol>
    </nav>
    <!-- End Breadcrumb -->

    <!-- Content -->
    <div class="row">

      <!-- Header -->
      <div class="col-md-12">
        <div *ngIf="cmsData" class="banner">
          <app-advertisements *ngIf="cmsData.espacios[1]" [typeContent]="'espacios'" [positions]="1"
            [contentData]="cmsData"></app-advertisements>
        </div>
        <h4 class="font-weight-bold text-primary">Indíquenos sus datos de contacto para el envío de información de
          acceso</h4>
        <!-- Content template cms-->
        <div *ngIf="cmsMessage" class="banner" [innerHTML]="cmsMessage.contenido">
        </div>
        <!-- End content template cms-->
      </div>
      <!-- End Header -->

      <!-- Form -->
      <div class="col-md-12 mb-4 order-md-1">
        <form class="form__default" name="form" (ngSubmit)="form.form.valid && onSubmit()" #form="ngForm">
          <div class="tab-pane" name="pills-profile">
            <!-- <h5 class="font-weight-bold text-primary mb-3">1. Información de Contacto Organizacional</h5> -->
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="far fa-user icon-form"></i></span>
                  </div>
                  <input type="text" appOnlyLetters class="form-control" placeholder="Nombre(s) *" name="contactName"
                    [(ngModel)]="supplierModel.contactName" name="contactName" #contactName="ngModel" required
                    maxlength="20" autofocus>
                </div>
                <div *ngIf="(contactName.touched && contactName.invalid) || !validateModel.contactName"
                  class="danger small">
                  <div *ngIf="supplierModel.contactName.length <= 0">El Nombre(s) es un campo Requerido</div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-user icon-form"></i></span>
                  </div>
                  <input type="text" appOnlyLetters class="form-control" placeholder="Apellido(s) * " maxlength="30"
                    name="contactLastname" [(ngModel)]="supplierModel.contactLastname" name="contactLastname"
                    #contactLastname="ngModel" required autofocus>
                </div>
                <div *ngIf="(contactLastname.touched && contactLastname.invalid) || !validateModel.contactLastname"
                  class="danger small">
                  <div *ngIf="supplierModel.contactLastname.length <= 0">El Apellido(s) es un campo Requerido</div>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="far fa-id-card icon-form"></i></span>
                  </div>
                  <select class="custom-select" autofocus [(ngModel)]="supplierModel.contactIdentificationType"
                    name="contactIdentificationType" #contactIdentificationType="ngModel" required>
                    <option value="">Elije un Tipo Identificación *</option>
                    <option *ngFor="let IdType of this.identfy" value="{{IdType.id}}">{{IdType.nombre}}</option>
                  </select>
                </div>
                <div
                  *ngIf="(contactIdentificationType.touched && contactIdentificationType.invalid) || !validateModel.contactIdentificationType"
                  class="danger small">
                  <div *ngIf="supplierModel.contactIdentificationType.length <= 0">El Tipo Identificación es un campo
                    Requerido
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-id-card icon-form"></i></span>
                  </div>
                  <input type="text" appOnlyNumbers class="form-control" placeholder="Numero de Identificación *"
                    maxlength="15" [disabled]="!supplierModel.contactIdentificationType"
                    (change)="validateContactIdentificationRegisterSupplier(supplierModel.contactIdentificationType,supplierModel.contactIdentification)"
                    [(ngModel)]="supplierModel.contactIdentification" name="contactIdentification"
                    #contactIdentification="ngModel" autofocus required>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="far fa-id-card icon-form"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Cargo" name="contactCharge" maxlength="30"
                    [(ngModel)]="supplierModel.contactCharge" name="contactCharge" #contactCharge="ngModel" autofocus>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-id-card icon-form"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Área" [(ngModel)]="supplierModel.contactArea"
                    name="contactArea" #contactArea="ngModel" autofocus maxlength="30">
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-mobile-alt icon-form"></i></span>
                  </div>
                  <input class="form-control col-3" placeholder="Indicativo *" autofocus required maxlength="5"
                    [(ngModel)]="supplierModel.contactIndicativeTelephone" name="contactIndicativeTelephone"
                    #contactIndicativeTelephone="ngModel">
                  <input appOnlyNumbers class="form-control" placeholder="Teléfono Móvil *" autofocus required
                    maxlength="10" minlength="10" [(ngModel)]="supplierModel.contactTelephone" name="contactTelephone"
                    #contactTelephone="ngModel">
                </div>
                <div
                  *ngIf="(contactIndicativeTelephone.touched && contactIndicativeTelephone.invalid) || !validateModel.contactIndicativeTelephone"
                  class="danger small">
                  <div *ngIf="supplierModel.contactIndicativeTelephone.length <= 0">El Indicativo es un campo Requerido
                  </div>
                  <div *ngIf="supplierModel.contactIndicativeTelephone.length > 4">El Indicativo no puede ser mayor a 4
                    digitos
                  </div>
                </div>
                <div *ngIf="(contactTelephone.touched && contactTelephone.invalid) || !validateModel.contactTelephone"
                  class="danger small">
                  <div *ngIf="supplierModel.contactTelephone.length <= 0">El Teléfono Móvil es un campo Requerido</div>
                  <div *ngIf="supplierModel.contactTelephone.length > 0 && supplierModel.contactTelephone.length != 10">
                    El Teléfono Móvil debe tener 10 digitos</div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-mobile-alt icon-form"></i></span>
                  </div>
                  <input type="email" class="form-control" placeholder="Email de Contacto *" maxlength="50"
                    [(ngModel)]="supplierModel.contactEmail" name="contactEmail"
                    (change)="validateEmailContactSupplier(supplierModel.contactEmail)" #contactEmail="ngModel" required
                    email autofocus>
                </div>
                <div *ngIf="(contactEmail.touched && contactEmail.invalid) || !validateModel.contactEmail"
                  class="danger small">
                  <div *ngIf="supplierModel.contactEmail.length <= 0">El Email de Contacto es un campo Requerido</div>
                </div>
                <div *ngIf="contactEmail.touched && contactEmail.invalid" class="danger small">
                  <div *ngIf="contactEmail.errors.email">Ingrese un Email válido</div>
                </div>
              </div>
              <!-- <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-map-marker-alt icon-form"></i></span>
                  </div>
                  <input class="form-control" placeholder="Dirección *" autofocus maxlength="40"
                    [(ngModel)]="supplierModel.contactAddress" name="contactAddress" #contactAddress="ngModel" required>
                </div>
                <div *ngIf="(contactAddress.touched && contactAddress.invalid) || !validateModel.contactAddress"
                  class="danger small">
                  <div *ngIf="supplierModel.contactAddress.length <= 0">La Dirección de Contacto es un campo Requerido
                  </div>
                </div>
              </div> -->
              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-city icon-form"></i></span>
                  </div>
                  <input type="text" appOnlyLetters class="form-control" placeholder="Empresa *" maxlength="20"
                    [(ngModel)]="supplierModel.businessName" name="businessName" #businessName="ngModel" required
                    autofocus>
                </div>
                <div *ngIf="(businessName.touched && businessName.invalid) || !validateModel.businessName"
                  class="danger small">
                  <div *ngIf="supplierModel.businessName.length <= 0">La Empresa es un campo Requerido</div>
                </div>
              </div>
              <div class="col-md-6 mb-3"></div>
              <div class="col-md-6 mb-3">
                <div class="input-group">
                  <re-captcha [(ngModel)]="supplierModel.captchaValue" name="captcha" #captcha="ngModel"></re-captcha>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="checkAcepto" name="checkAcepto" autofocus
                    [(ngModel)]="supplierModel.checkAcepto" #checkAcepto="ngModel">
                  <label class="form-check-label" for="checkAcepto"><a [routerLink]="['','terms']"
                      target="_blank">Acepto términos y condiciones</a></label>
                  <br>
                  <input type="checkbox" class="form-check-input" id="checkAutorizo" name="checkAutorizo" autofocus
                    [(ngModel)]="supplierModel.checkAutorizo" #checkAutorizo="ngModel">
                  <label class="form-check-label" for="checkAutorizo"><a [routerLink]="['','privacy']"
                      target="_blank">Autorizo el tratamiento de datos</a></label>
                </div>
              </div>
            </div>
            <div class="duo-btn-movil">
              <button type="submit" (click)="validateForm()"
                class="btn btn-primary float-right btn-movil btn-icon-split mb-3">
                <span class="text">Finalizar</span>
                <span class="icon text-white-50">
                  <i class="fa fa-paper-plane"></i>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- End Form -->

    </div>
    <!-- End Content -->
    <div class="col-md-12">
      <div *ngIf="cmsData" class="banner">
        <app-advertisements *ngIf="cmsData.espacios[3]" [typeContent]="'espacios'" [positions]="3"
          [contentData]="cmsData"></app-advertisements>
      </div>
    </div>

  </div>
  <!-- End Begin Page Content -->
</main>
<!-- Main Content -->