<app-header *ngIf="userLogged" [b2sContent]="b2sContent"></app-header>

<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-primary topbar shadow">

  <a class="navbar-brand" [routerLink]="['/login']">
    <img class="logo" src="{{logo}}" alt="Logo">
  </a>

  <div class="collapse navbar-collapse" id="navbarCollapse">

    <ul class="navbar-nav mr-auto">
    </ul>

    <ul class="navbar-nav">
      <li class="nav-item">
        <a [routerLink]="['','login']" class="btn btn-link btn-block" style="color:#ffffff; cursor: pointer;">Iniciar
          Sesión</a>
      </li>
    </ul>
  </div>
</nav>

<!-- Main Content -->
<main role="main" class="content__main">
  <!-- Begin Begin Page Content -->
  <div class="container pt-3 d-flex flex-column align-items-center">
    <!-- Title -->
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h1 class="font-weight-bold h3 mb-0 text-gray">Registro de Proveedores</h1>
    </div>
    <!-- End Title -->

    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/index"><i class="fas fa-home"></i></a></li>
        <li class="breadcrumb-item active" aria-current="page">Registro de Proveedores</li>
      </ol>
    </nav>
    <!-- End Breadcrumb -->

    <!-- Content -->
    <div class="row">
      <div [innerHTML]="templateData || 'Sin información'"></div>
    </div>

  </div>
  <!-- End Begin Page Content -->
</main>
<!-- Main Content -->