<section class="login-container">
  <div class="container-fluid px-0 h-100">
    <div class="row mx-0 h-100">
      <div class="col-12 col-lg-6 login-img-column">
        <div class="login-badge">
          Sistema de Gestión de Proveedores
        </div>
        <img class="login-logo" src="{{logo}}">
        <img class="login-img" src="{{imagen_login}}">
      </div>
      <div class="col-12 col-lg-6 login-form-column">
        <section class="login-form-container">
          <div class="row">
            <div class="col-10 col-md-8 col-lg-6 mx-auto">
              <form novalidate="" #forma="ngForm" (ngSubmit)="authentication(forma)" class="form-signin">
                <div class="text-center mb-4">
                  <h1 style="font-size: 2vw;">Bienvenidos {{ company_prin }}</h1>
                  <p>Por favor ingrese sus datos de acceso<span *ngIf="mostrarBotonRegistroProveedores">, sino tiene un
                      acceso por favor <a [routerLink]="['','search-company']">registrese como proveedor</a></span>.</p>
                </div>
                <div class="form-group">
                  <label>Correo Electrónico</label>
                  <input [(ngModel)]="dataForm.inputEmail" type="email" email #inputEmail="ngModel" name="inputEmail"
                    class="form-control  form-control-lg" placeholder="Correo Electrónico" required autofocus
                    pattern='^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$'>
                  <div *ngIf="inputEmail.invalid">
                    <div class="invalid-feedback" *ngIf="inputEmail.errors.required">
                      Ingrese el correo electrónico.
                    </div>
                    <div class="invalid-feedback" *ngIf="inputEmail.errors.pattern">
                      Ingrese un correo válido.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Contraseña</label>
                  <input [(ngModel)]="dataForm.inputPassword" type="password" #inputPassword="ngModel"
                    name="inputPassword" class="form-control form-control-lg" placeholder="Contraseña" required>
                  <div *ngIf="inputPassword.invalid">
                    <div class="invalid-feedback" *ngIf="inputPassword.errors.required">
                      Ingrese la contraseña.
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label>Seleccione la Compañía</label>
                  <select [(ngModel)]="dataForm.inputCompany" class="custom-select custom-select-lg"
                    #inputCompany="ngModel" name="inputCompany" placeholder="Compañia">
                    <option *ngFor="let tercero of thirdCompanies | keyvalue" value="{{tercero.key}}">{{tercero.value}}
                    </option>
                  </select>
                  <div *ngIf="inputCompany.invalid">
                    <div class="invalid-feedback" *ngIf="inputCompany.errors.required">
                      Ingrese la Compañia.
                    </div>
                  </div>
                </div> -->
                <button type="submit" class="btn btn-primary btn-block btn-lg">
                  Iniciar Sesión
                </button>
                <a [routerLink]="['','password-recovery']" class="btn btn-link btn-block">
                  Olvidé mi Contraseña
                </a>
                <div *ngIf="contentCms" [innerHTML]="contentCms.contenido" class="text-center mt-4"></div>
                <div #sealRef></div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>