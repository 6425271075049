import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

import { SearchCompanyService } from '@src/app/common/services/search-company.service';
import { AdverstismentService } from '@src/app/common/services/adverstisment.service';
import { selectAllTemplateOpen } from '@src/app/store/selectors/unlayer.selectors';
import { AddUnlayerAction } from '@src/app/store/actions/unlayer.actions';
import { AppState } from '@src/app/store/state/app-state';

import { filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-supplier-registration',
  templateUrl: './supplier-registration.component.html',
  styleUrls: ['./supplier-registration.component.scss']
})
export class SupplierRegistrationComponent implements OnInit {

  private ngUnsubscribe = new Subject<void>();
  isFirstNavigation: boolean;
  selectedCompany: string;
  templateName: string;
  userLogged: string;
  templateData: any;
  b2sContent: any;
  logo: string;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    public adService: AdverstismentService,
    private sanitizer: DomSanitizer,
    private _searchCompanyService: SearchCompanyService,
    private renderer: Renderer2
  ) {
    this.store.dispatch(new AddUnlayerAction(true));
    this.store.pipe(select(selectAllTemplateOpen))
      .subscribe(templateOpen => {
        this.isFirstNavigation = templateOpen
      })
  }
  private initialized: boolean = false;
  @ViewChild('form', { static: false }) form!: ElementRef<HTMLFormElement>;
  captchaResponse: string = '';
  isFormValid: boolean = true;
  missingFields: string[] = [];
  recaptcha: boolean = false;

  ngOnInit() {

    this.selectedCompany = localStorage.getItem('selectedCompany');
    this.userLogged = JSON.parse(localStorage.getItem('authenticate'));
    this.templateName = "AGP - Formulario de Registro de Proveedores"

    const json = {
      data: 20,
    };
    this.getLoginService(json);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === this.router.url && this.isFirstNavigation) {
        location.reload()
      }
    });

    this.getTemplateData()
  }

  ngAfterViewChecked(): void {
    if (this.templateData && !this.initialized) {
      this.addChangeEventListeners();
      this.loadRecaptchaScript()

      this.initialized = true;
    }
  }

  getTemplateData() {
    this.adService.getTemplateHtml(this.templateName).subscribe((data: any) => {
      let htmlString = data.html;
      htmlString = htmlString.replace(/onchange="return /g, '(change)="');
      htmlString = htmlString.replace(/onclick/g, '(click)="');
      htmlString = htmlString.replace(/onsubmit/g, '(submit)="');
      this.templateData = this.sanitizer.bypassSecurityTrustHtml(htmlString);
    });
  }

  getLoginService(params) {
    this._searchCompanyService.getDataLogin(params).subscribe(
      (response: any) => {
        if (response.error) {
          this.logo = localStorage.getItem('logo');
        } else {
          this.logo = response.logo;
        }
      }, (error: any) => {
        this.logo = localStorage.getItem('logo');
      }
    );
  }

  addChangeEventListeners(): void {
    setTimeout(() => {
      const fileInputs = document.querySelectorAll('input[id^="input_attachment_"][type="file"]');
      fileInputs.forEach((input) => {
        if (!input.hasAttribute('data-event-bound')) {
          input.addEventListener('change', () => this.isFilledFileInput());
          input.setAttribute('data-event-bound', 'true');
        }
      });

      const options = document.querySelectorAll('option');

      options.forEach((option: any) => {

        if (option.value) {
          let content = option.textContent;

          if (content.length > 70) {
            content = content.slice(0, 70) + '...';
          }

          this.renderer.setProperty(option, 'textContent', content);
          this.renderer.setAttribute(option, 'title', option.value);
        }
      });

      const acceptBtn = document.getElementById("accept_btn");
      if (!acceptBtn.hasAttribute('data-event-bound')) {
        acceptBtn.addEventListener('click', () => this.uploadFiles());
        acceptBtn.setAttribute('data-event-bound', 'true');
      }

      const submitBtn = document.querySelector('button[type="submit"]');

      if (submitBtn && !submitBtn.hasAttribute('data-event-bound')) {
        submitBtn.addEventListener('click', () => this.validateForm());
        submitBtn.setAttribute('data-event-bound', 'true');
      }

      this.recaptcha = !!document.querySelector('.g-recaptcha');

      if (this.recaptcha) {
        const form = document.querySelector('form');

        if (form) {
          form.addEventListener('submit', (e) => this.validateRecaptcha(e));
        }
      }

    }, 200);
  }

  uploadFiles(): void {
    const fileUploads = document.querySelectorAll('#attachment-inputs input[type="file"]');
    fileUploads.forEach((fileUpload) => {
      console.log(fileUpload);
    });
  }

  isFilledFileInput(): void {
    const fileUploadsInputs = document.querySelectorAll('#attachment-inputs input[type="file"]');
    fileUploadsInputs.forEach((uploadInput: HTMLInputElement) => {
      if (uploadInput.files.length > 0) {
        uploadInput.addEventListener('invalid', function (e) {
          e.preventDefault();
          e.stopPropagation();
          uploadInput.classList.add('required');
        });
      } else {
        uploadInput.addEventListener('valid', function (e) {
          e.preventDefault();
          e.stopPropagation();
          uploadInput.classList.remove('required');
        });
      }
    });
  }

  validateForm(): void {
    const requiredFields = document.querySelectorAll('.required');
    this.missingFields = [];
    this.isFormValid = true;

    requiredFields.forEach((requiredField) => {

      if ((requiredField as HTMLInputElement).value.trim() === '') {
        if (!this.missingFields.includes(requiredField.getAttribute('aria-label'))) {
          this.missingFields.push(requiredField.getAttribute('aria-label'))
        }
        this.isFormValid = false;
      }

    });

    if (this.recaptcha) {

      let response = grecaptcha.getResponse();

      if (response.length === 0) {

        this.isFormValid = false;

        if (!this.missingFields.includes('Captcha')) {
          this.missingFields.push('Captcha');
        }

      }
    }

    if (this.isFormValid && this.form) {
      this.form.nativeElement.submit();
    } else if (!this.isFormValid) {
      alert('Por favor, llene todos los campos obligatorios:\n' + this.missingFields.join('\n'));
    }

  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    console.log(`Resolved captcha with response: ${this.captchaResponse}`);
  }

  loadRecaptchaScript() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  validateRecaptcha(event) {
    if (this.recaptcha) {
      let response = grecaptcha.getResponse();
      if (response.length === 0) {
        event.preventDefault();
      }
    }
  }

}
